<template>
  <div class="set-document">
    <v-row dense>
      <v-col cols="12" md="12" sm="12">
        <v-row dense>
          <v-col cols="12" md="12" sm="12">
            <h2>Datos del centro</h2>
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <strong>Centro: </strong>
            <br />
            {{ electronico.body.establishment_name }}
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <strong>Dirección: </strong>
            <br />
            {{ electronico.body.establishment_address }}
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <v-autocomplete
              v-model="electronico.body.establishment_municipality"
              item-text="name"
              item-value="id"
              :items="electronico.call.municipalities"
              :filter="customFilter"
              label="Municipio"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <strong>Telefono: </strong>
            <br />
            {{ electronico.body.establishment_phone }}
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <strong>Correo: </strong>
            <br />
            {{ electronico.body.establishment_email }}
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <v-select
              label="Resoluciones Registradas"
              v-model="resolution"
              :items="resoluciones_list"
              return-object
              @change="setResolutions"
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <v-row dense>
          <v-col cols="12" md="12" sm="12">
            <h2>Datos del cliente</h2>
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <strong>Cliente: </strong>
            <br />
            {{ electronico.body.customer.name }}
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <v-select
              label="Tipo de Documento"
              v-model="
                electronico.body.customer.type_document_identification_id
              "
              :items="electronico.call.type_document_identifications"
              item-text="name"
              item-value="id"
            ></v-select>
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <v-text-field
              type="number"
              label="Numero de Documento"
              v-model.number="electronico.body.customer.identification_number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <v-text-field
              type="text"
              label="Dirección"
              v-model="electronico.body.customer.address"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <v-autocomplete
              v-model="electronico.body.customer.municipality_id"
              item-text="name"
              item-value="id"
              :items="electronico.call.municipalities"
              :filter="customFilter"
              label="Municipio"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <v-text-field
              type="text"
              label="Telefono"
              v-model="electronico.body.customer.phone"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-text-field
              type="text"
              label="Correo"
              v-model="electronico.body.customer.email"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
            sm="6"
            v-if="
              electronico.body.customer.type_document_identification_id == 6
            "
          >
            <v-text-field
              type="number"
              label="Digito de Verificación"
              v-model="electronico.body.customer.dv"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
            sm="6"
            v-if="
              electronico.body.customer.type_document_identification_id == 6
            "
          >
            <v-text-field
              type="text"
              label="Registro Mercantil"
              v-model="electronico.body.customer.merchant_registration"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-select
              label="Regimen"
              v-model="electronico.body.customer.type_regime_id"
              :items="electronico.call.type_regimes"
              item-text="name"
              item-value="id"
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="12">
        <v-row dense>
          <v-col cols="12" md="12" sm="12">
            <h2>Datos de la factura</h2>
            <h3>Productos / Servicios</h3>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Codigo</th>
                    <th>Descripción</th>
                    <th>Cantidad</th>
                    <th>UM</th>
                    <th>Val Uni</th>
                    <th>IVA</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in electronico.body.invoice_lines"
                    :key="index"
                  >
                    <td>{{ item.code }}</td>
                    <td>{{ item.description }}</td>
                    <td>{{ item.invoiced_quantity }}</td>
                    <td>
                      <v-select
                        label="Unidad de Medida"
                        v-model="item.unit_measure_id"
                        :items="electronico.call.unit_measures"
                        item-text="name"
                        item-value="id"
                      ></v-select>
                    </td>
                    <td>
                      <span v-if="item.tax_totals != undefined">
                        {{
                          parseFloat(item.tax_totals[0].taxable_amount)
                            | currency
                        }}
                      </span>
                      <span v-else>
                        {{ parseFloat(item.price_amount) | currency }}
                      </span>
                    </td>
                    <td>
                      <span v-if="item.tax_totals != undefined">{{
                        parseFloat(item.tax_totals[0].tax_amount) | currency
                      }}</span>
                      <span v-else>$ 0</span>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th colspan="2">Medio de Pago</th>
                    <td colspan="4">
                      <v-select
                        :full-width="false"
                        label="Medio de Pago"
                        v-model="
                          electronico.body.payment_form.payment_method_id
                        "
                        :items="electronico.call.payment_methods"
                        item-text="name"
                        item-value="id"
                      ></v-select>
                    </td>
                  </tr>
                  <tr>
                    <th colspan="4">Nro Lineas</th>
                    <td colspan="2">
                      {{ electronico.body.invoice_lines.length }}
                    </td>
                  </tr>
                  <tr>
                    <th colspan="4">Subtotal</th>
                    <td colspan="2">
                      {{
                        parseFloat(
                          electronico.body.legal_monetary_totals
                            .line_extension_amount
                        ) | currency
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th colspan="4">IVA</th>
                    <td colspan="2">{{ total_iva | currency }}</td>
                  </tr>
                  <tr>
                    <th colspan="4">Total</th>
                    <td colspan="2">
                      {{
                        parseFloat(
                          electronico.body.legal_monetary_totals.payable_amount
                        ) | currency
                      }}
                    </td>
                  </tr>
                </tfoot>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-btn
          :dark="!loading"
          :color="!loading ? 'black' : 'gray'"
          :loading="loading"
          :disabled="loading"
          @click="sendDocument()"
          v-if="btn_send"
        >
          Enviar Documento DIAN
        </v-btn>
      </v-col>
      <v-col cols="12" md="6">
        <v-btn text color="error" @click="$emit('setCancelEnvio', true)" small v-if="btn_send">
          No enviar Documento DIAN
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "setDocument",
  props: ["electronico", "factura_id"],
  data: () => ({
    resolution: null,
    loading: false,
    baseURL: "https://apirestdian.luxcultury.com/api",
    btn_send: true,
  }),
  methods: {
    customFilter(item, queryText) {
      const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      };
      const text = removeAccents(queryText);
      const textOne = removeAccents(item.name).toLowerCase();
      const searchText = text.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    setResolutions(input) {
      this.electronico.body.type_document_id = input.type_document_id;
      this.electronico.body.resolution_number = input.resolution;
      this.electronico.body.prefix = input.prefix;
    },
    sendDocument() {
      this.loading = true;
      let state_document = false;
      let url_api = "/ubl2.1/invoice";
      if (this.electronico.call.testSetId != null)
        url_api = `${url_api}/${this.electronico.call.testSetId}`;

      if (this.electronico.body.customer.type_document_identification_id != 6) {
        this.electronico.body.customer.dv = null;
        this.electronico.body.customer.merchant_registration = "0000000-00";
      }

      if (this.electronico.body.customer.type_document_identification_id == 6) {
        if (
          this.electronico.body.customer.dv != null &&
          this.electronico.body.customer.merchant_registration != ""
        ) {
          state_document = true;
        }
      } else {
        state_document = true;
      }

      if (state_document) {
        axios({
          method: "POST",
          baseURL: this.baseURL,
          url: url_api,
          headers: {
            Authorization: `Bearer ${this.electronico.call.auth_token}`,
          },
          data: this.electronico.body,
        })
          .then((response) => {
            if (this.electronico.call.testSetId != null) {
              this.update_factura_hab(response.data);
            } else {
              this.update_factura(response.data);
            }
          })
          .finally(() => (this.loading = false));
      } else {
        let alert = {
          alert: true,
          color: "error",
          text:
            "Verifica que el digito de verificación y/o registro mercantil estén correctamente diligenciados.",
        };
        this.$store.dispatch("setAlert", alert);

        this.loading = false;
      }
    },
    update_factura_hab(data) {
      let update_factura = {
        zip_key: "",
        folio_dian: "",
        cufe: "",
        attached: "",
      };
      update_factura.folio_dian = this.electronico.body.number;
      update_factura.zip_key =
        data.ResponseDian.Envelope.Body.SendTestSetAsyncResponse.SendTestSetAsyncResult.ZipKey;
      update_factura.cufe = data.cufe;
      update_factura.attached = data.urlinvoicepdf;

      const body = {
        route: "/update_f_electronica",
        data: { update: update_factura, factura: this.factura_id },
      };

      this.$store
        .dispatch("axios_post", body)
        .then((response) => {
          let alert = {
            alert: true,
            color: "success",
            text: `${data.message}.\r\n${response.data.data}`,
          };
          this.$store.dispatch("setAlert", alert);
          this.btn_send = false;
          this.$emit("setCancel", true);
          this.$emit("setPrint", true);
          this.$emit("update");
        })
        .finally(() => (this.loading = false));
    },
    update_factura(data) {
      const body_response =
        data.ResponseDian.Envelope.Body.SendBillSyncResponse.SendBillSyncResult;

      let update_factura;

      if (body_response.IsValid == "true") {
        update_factura = {
          folio_dian: this.electronico.body.number,
          cufe: data.cufe,
          attached: data.urlinvoicepdf,
          dian_state: 1,
        };
      } else {
        update_factura = {
          folio_dian: this.electronico.body.number,
          cufe: data.cufe,
          attached: data.urlinvoicepdf,
          dian_state: 0,
          error_message: JSON.stringify(body_response.ErrorMessage),
        };
      }
      const body = {
        route: "/update_f_electronica",
        data: { update: update_factura, factura: this.factura_id },
      };

      this.$store
        .dispatch("axios_post", body)
        .then((response) => {
          let alert = {
            alert: true,
            color: "success",
            text: `${data.message}.\r\n${response.data.data}`,
          };
          this.$store.dispatch("setAlert", alert);

          if (body_response.IsValid == "true") this.sendMailZip();

          this.btn_send = false;
          this.$emit("setCancel", true);
          this.$emit("setPrint", true);
          this.$emit("update");
        })
        .finally(() => (this.loading = false));
    },
    sendMailZip() {
      const body = {
        route: "/send_documento_electronico",
        params: {
          id: this.factura_id,
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) {
          let alert = {
            alert: true,
            color: "success",
            text: response.data.data,
          };
          this.$store.dispatch("setAlert", alert);
        } else {
          let alert = {
            alert: true,
            color: "error",
            text: response.data.data,
          };
          this.$store.dispatch("setAlert", alert);
        }
      });
    },
  },
  created() {
    if (this.electronico.call.testSetId == null) {
      const resoluciones = this.resoluciones_list.filter((resolucion) => {
        return resolucion.prefix != "SETP";
      });
      this.resolution = resoluciones[0];
      this.electronico.body.type_document_id = resoluciones[0].type_document_id;
      this.electronico.body.resolution_number = resoluciones[0].resolution;
      this.electronico.body.prefix = resoluciones[0].prefix;
    } else {
      this.resolution = this.resoluciones_list[0];
      this.electronico.body.type_document_id = this.resoluciones_list[0].type_document_id;
      this.electronico.body.resolution_number = this.resoluciones_list[0].resolution;
      this.electronico.body.prefix = this.resoluciones_list[0].prefix;
    }
  },
  computed: {
    resoluciones_list() {
      const resoluciones = this.electronico.call.resoluciones;
      return resoluciones.map((resolucion) => {
        resolucion.type_document_id = parseInt(resolucion.type_document_id);
        return Object.assign(
          { text: `${resolucion.prefix} - ${resolucion.resolution}` },
          resolucion
        );
      });
    },
    total_iva() {
      if (this.electronico.body.tax_totals == undefined) return 0;

      return this.electronico.body.tax_totals.reduce((index, item) => {
        return index + parseFloat(item.tax_amount);
      }, 0);
    },
  },
};
</script>
